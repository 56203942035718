<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tipos de Vehículo</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Tipos de Vehículo</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Tipos de Vehículo</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Caracteriacticas"
                    data-toggle="tab"
                    href="#Caracteristicas"
                    v-if="id != 0"
                    >Caracteristicas</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="nombre">Nombre Tipo de Vehículo</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nombre"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaNombre"
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="cantidad_pasajeros"
                            >Cantidad de pasajeros</label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="cantidad_pasajeros"
                            v-model="form.cantidad_pasajeros"
                            :class="
                              $v.form.cantidad_pasajeros.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="sitio">Clasificación</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.tipo"
                          :class="
                            $v.form.tipo.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_vehi in objLista.tipo_vehiculo"
                            :key="tipo_vehi.numeracion"
                            :value="tipo_vehi.numeracion"
                          >
                            {{ tipo_vehi.descripcion }}
                          </option>
                        </select>
                        <div class="error" v-if="!$v.form.tipo.required">
                          Seleccione un tipo de vehículo
                        </div>
                      </div>
                      <div class="form-group col-md-3" v-if="form.tipo > 0">
                        <label for="confirmacionVehi"
                          >¿Requiere
                          {{ form.tipo == 1 ? "Trailer" : "Cabezote" }}?</label
                        >
                        <div class="input-group">
                          <div class="custom-control custom-radio m-1">
                            <input
                              class="m-1"
                              type="radio"
                              name="confirmacionVehi"
                              id="confirmacionVehi_si"
                              value="1"
                              v-model="form.check_tipo"
                              @change="validarCheck(1)"
                            />
                            <label
                              class="form-check-label"
                              for="confirmacionVehi_si"
                            >
                              Si
                            </label>
                          </div>
                          <div class="custom-control custom-radio m-1">
                            <input
                              class="m-1"
                              type="radio"
                              name="confirmacionVehi"
                              id="confirmacionVehi_no"
                              value="0"
                              v-model="form.check_tipo"
                              @change="validarCheck(1)"
                              :class="
                                $v.check.$invalid ? 'is-invalid' : 'is-valid'
                              "
                            />
                            <label
                              class="form-check-label"
                              for="confirmacionVehi_no"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <TipoVehiculoLinea
                          v-if="
                            form.id != null &&
                              $store.getters.can(
                                'admin.vehiculos.assingOrRevokeLinea'
                              )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Caracteristicas" v-if="id != 0">
                  <div class="card-body">
                    <!-- Inicio del formulario -->
                    <div class="row" v-show="!$v.form.$invalid">
                      <div class="form-group col-md-4">
                        <label for="sitio">Nombre del campo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="formCaracteristicas.nombre_campo"
                          :class="
                            $v.formCaracteristicas.nombre_campo.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <div
                          class="error"
                          v-if="!$v.formCaracteristicas.nombre_campo.required"
                        >
                          Ingrese un nombre del campo
                        </div>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="sitio">Tipo de Campo</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="formCaracteristicas.tipo_campo"
                          :class="
                            $v.formCaracteristicas.tipo_campo.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_campo in objLista.tipos_campos"
                            :key="tipo_campo.numeracion"
                            :value="tipo_campo.numeracion"
                          >
                            {{ tipo_campo.descripcion }}
                          </option>
                        </select>
                        <div
                          class="error"
                          v-if="!$v.formCaracteristicas.tipo_campo.required"
                        >
                          Seleccione un tipo de campo
                        </div>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="sitio"> Tamaño del Campo</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="formCaracteristicas.tamano_campo"
                          :class="
                            $v.formCaracteristicas.tamano_campo.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <div
                          class="error"
                          v-if="!$v.formCaracteristicas.tamano_campo.required"
                        >
                          Ingrese un tamaño de campo
                        </div>
                      </div>
                      <div class="form-group col-md-1">
                        <label for="sitio">Obligatorio</label>
                        <div class="be-check">
                          <input
                            type="checkbox"
                            class="form-control form-control-sm"
                            v-model="formCaracteristicas.obligatorio"
                          />
                        </div>
                      </div>
                      <div class="form-group col-md-1 text-right">
                        <br />
                        <button
                          class="btn bg-primary col-md-6 mt-1"
                          v-show="!$v.formCaracteristicas.$invalid"
                          @click="saveCaracteristica()"
                        >
                          <i class="fa fa-plus-circle"></i>
                        </button>
                        <button
                          class="btn bg-danger col-md-6 mt-1"
                          v-show="formCaracteristicas.id"
                          @click="clearForm()"
                        >
                          <i class="fa fa-eraser"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 p-0">
                        <table
                          id="sitios"
                          class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                          "
                        >
                          <thead>
                            <tr>
                              <th>Nombre Campo</th>
                              <th>Tipo Campo</th>
                              <th>Tamaño Campo</th>
                              <th>Obligatorio</th>
                              <th>Acción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="data in caracteristicas.data"
                              :key="data.id"
                            >
                              <td v-text="data.nombre_campo"></td>
                              <td v-text="data.nTipoCampo"></td>
                              <td v-text="data.tamano_campo"></td>
                              <td class="text-center">
                                <span
                                  v-if="data.obligatorio == 1"
                                  class="badge badge-danger"
                                >
                                  SI
                                </span>
                                <span
                                  v-if="data.obligatorio == 0"
                                  class="badge badge-success"
                                >
                                  NO
                                </span>
                              </td>
                              <td class="text-right">
                                <button
                                  type="button"
                                  class="btn btn-sm bg-navy"
                                  @click="editCaracteristica(data.id)"
                                  v-if="
                                    $store.getters.can(
                                      'admin.caracteristicasVehiculo.edit'
                                    )
                                  "
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-danger btn-sm"
                                  @click="removeCaracteristica(data.id)"
                                  v-if="
                                    $store.getters.can(
                                      'admin.caracteristicasVehiculo.delete'
                                    )
                                  "
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="float-left">
                          <p>
                            Mostrando del <b>{{ caracteristicas.from }}</b> al
                            <b>{{ caracteristicas.to }}</b> de un total:
                            <b>{{ caracteristicas.total }}</b> Registros
                          </p>
                        </div>
                        <pagination
                          :data="caracteristicas"
                          @pagination-change-page="getCaracteristicasVehiculo"
                          :limit="5"
                          align="right"
                        ></pagination>
                      </div>
                    </div>
                    <!-- Fin del formulario -->
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can(
                        'admin.caracteristicasVehiculo.create'
                      ) ||
                        $store.getters.can('admin.caracteristicasVehiculo.edit')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import TipoVehiculoLinea from "./TipoVehiculoLinea";

export default {
  name: "TipoVehiculoForm",
  components: {
    pagination,
    TipoVehiculoLinea,
  },
  data() {
    return {
      form: {},
      formCaracteristicas: {},
      accion: "",
      metodo: "",
      caracteristicas: {},
      objLista: {
        tipos_campos: [],
        tipo_vehiculo: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      id: this.$route.params.id,
    };
  },
  validations: {
    check: {
      required,
    },
    form: {
      nombre: {
        required,
      },
      cantidad_pasajeros: {
        required,
      },
      tipo: {
        required,
      },
    },
    formCaracteristicas: {
      nombre_campo: {
        required,
      },
      tipo_campo: {
        required,
      },
      tamano_campo: {
        required,
      },
      obligatorio: {},
    },
  },
  methods: {
    getCaracteristicasVehiculo() {
      axios
        .get(`/api/admin/caracteristicasVehiculo/${this.id}`)
        .then((response) => {
          this.caracteristicas = response.data;
        });
    },

    getTiposCampos() {
      axios.get("/api/lista/15").then((response) => {
        this.objLista.tipos_campos = response.data;
      });
    },

    validarCheck(act) {
      if (act == 1) {
        if (this.form.tipo == 1) {
          if (this.form.check_tipo == 1) {
            this.form.check_tipo = 2;
          } else {
            this.form.check_tipo = 0;
          }
        } else if (this.form.tipo == 2) {
          if (this.form.check_tipo == 1) {
            this.form.check_tipo = 1;
          } else {
            this.form.check_tipo = 0;
          }
        } else if (this.form.tipo == 0) {
          this.form.check_tipo = 0;
        }
      } else {
        if (this.form.check_tipo == 1 || this.form.check_tipo == 2) {
          this.form.check_tipo = 1;
        }
      }
    },

    getClasificadorTiposVehiculos() {
      axios.get("/api/lista/185").then((response) => {
        this.objLista.tipo_vehiculo = response.data;
      });
    },

    saveCaracteristica() {
      this.formCaracteristicas.tipo_vehiculo_id = this.form.id;
      if (!this.$v.formCaracteristicas.$invalid) {
        axios({
          method: this.metodo,
          url: `/api/admin/caracteristicasVehiculo/${this.id}`,
          data: this.formCaracteristicas,
        })
          .then(() => {
            this.formCaracteristicas = {};
            this.getCaracteristicasVehiculo();
            this.$swal({
              icon: "success",
              title: "Se modificó la característica exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    editCaracteristica(id) {
      axios
        .get(`/api/admin/caracteristicasVehiculo/show/${this.id}/${id}`)
        .then((response) => {
          this.formCaracteristicas = response.data;
        });
    },

    clearForm() {
      this.formCaracteristicas = {};
    },

    removeCaracteristica(id) {
      this.$swal({
        title: "Esta seguro de retirar esta Característica?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "delete",
            url: `/api/admin/caracteristicasVehiculo/${this.id}/${id}`,
          }).then((response) => {
            this.getCaracteristicasVehiculo();
            this.$swal({
              icon: "success",
              title: "Se eliminó la característica exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    validaNombre() {
      if (this.form.nombre) {
        let id = [];
        if (this.form.id) {
          id.push(this.form.id);
        }
        let filtro = {
          not_id: id,
          nombre_igual: this.form.nombre,
        };
        axios
          .get("/api/admin/tiposVehiculos/lista", {
            params: filtro,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.$swal({
                icon: "error",
                title: "El Tipo de vehículo digitado ya está creado...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.form.nombre = null;
            }
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/admin/tiposVehiculos",
          data: this.form,
        })
          .then((response) => {            
            this.$swal({
              icon: "success",
              title: "Se actualizo el tipo de vehículo...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/TiposVehiculo");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.validarCheck(0);
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },
  },
  mounted() {
    this.init();
    this.getCaracteristicasVehiculo();
    this.getTiposCampos();
    this.getClasificadorTiposVehiculos();
  },
};
</script>
